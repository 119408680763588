import { Loader } from '@components/loader.tsx';
import { Login } from '@pages/auth/login.tsx';
import { Navigate, Route } from 'react-router-dom';

export enum AuthRoute {
  Login = '/login',
  AuthRedirect = '/redirect',
}

export const authRoutes = () => (
  <>
    <Route path="*" element={<Navigate to={AuthRoute.Login} />} />
    <Route path={AuthRoute.AuthRedirect} element={<Loader isAbsolute />} />
    <Route path={AuthRoute.Login} element={<Login />} />
  </>
);
