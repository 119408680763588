import { UseFormSetError } from 'react-hook-form';
import { toast } from 'react-toastify';
import { sentenceCase } from 'sentence-case';

import { APIError } from '@/api';

interface Error {
  message?: string | Record<string, unknown>;
}

// FIXME: make part of facade and useQuery/Mutation
export const handle = (
  apiError: APIError | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setError?: UseFormSetError<any>,
): Error | undefined => {
  if (!apiError) {
    return;
  }

  const message = apiError.response?.data.message ?? apiError.message;

  if (import.meta.env.DEV) {
    // eslint-disable-next-line no-console
    console.error(apiError);
  }

  if (message) {
    toast.error(
      typeof message === 'string' ? message : JSON.stringify(message),
    );
  }
  if (typeof message === 'string') {
    return;
  }
  if (setError) {
    applyErrors(message, setError);
  }

  return { message };
};

const applyErrors = (
  errors: Record<string, unknown>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setError: UseFormSetError<any>,
  parentPath = '',
) => {
  Object.entries(errors).forEach(([key, value]) => {
    const path = parentPath ? `${parentPath}.${key}` : key;

    if (typeof value === 'object') {
      applyErrors(value as NonNullable<unknown>, setError, path);
    } else {
      setError(path, {
        type: 'manual',
        message: sentenceCase(value as string),
      });
    }
  });
};
