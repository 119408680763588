import { APIError } from '@api/api.ts';
import {
  MutateFunction,
  MutateOptions,
  QueryFunction,
  QueryOptions,
  useMutation,
  useQuery as useQueryTanstack,
} from '@tanstack/react-query';

export type QueryKey<Endpoint, Body> = [Endpoint, Body | undefined];

export function useFacadeQuery() {
  function query<Endpoint, Body = undefined, ReturnData = unknown>(
    key: QueryKey<Endpoint, Body>,
    queryFn: QueryFunction<ReturnData, QueryKey<Endpoint, Body>>,
    options?: QueryOptions<
      ReturnData,
      APIError,
      unknown,
      QueryKey<Endpoint, Body>
    >,
  ) {
    return useQueryTanstack<
      ReturnData,
      APIError,
      ReturnData,
      QueryKey<Endpoint, Body>
      // @ts-expect-error generic default type mismatch
    >({
      queryKey: key,
      queryFn,
      retry: false,
      ...options,
    });
  }

  function mutate<Endpoint, Body, ReturnData>(
    key: [Endpoint],
    mutationFn: MutateFunction<ReturnData, APIError, Body>,
    options?: MutateOptions<ReturnData, APIError, Body>,
  ) {
    return useMutation<ReturnData, APIError, Body>({
      mutationKey: key,
      mutationFn,
      retry: false,
      ...options,
    });
  }

  return { query, mutate };
}
