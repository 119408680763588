/// <reference types="vite-plugin-svgr/client" />
import { MouseEventHandler } from 'react';
import { toast } from 'react-toastify';

import ArrowRight from '@/assets/icons/arrowRight.svg?react';
import Arrows from '@/assets/icons/arrows.svg?react';
import ArrowUpRight from '@/assets/icons/arrowUpRight.svg?react';
import Building from '@/assets/icons/building.svg?react';
import Calendar from '@/assets/icons/calendar.svg?react';
import Chevron from '@/assets/icons/chevron.svg?react';
import Close from '@/assets/icons/close.svg?react';
import CloseSmall from '@/assets/icons/closeSmall.svg?react';
import Copy from '@/assets/icons/copy.svg?react';
import Doc from '@/assets/icons/doc.svg?react';
import Dot from '@/assets/icons/dot.svg?react';
import Download from '@/assets/icons/download.svg?react';
import Edit from '@/assets/icons/edit.svg?react';
import Education from '@/assets/icons/education.svg?react';
import Email from '@/assets/icons/email.svg?react';
import Industry from '@/assets/icons/industry.svg?react';
import Mail from '@/assets/icons/mail.svg?react';
import MailResend from '@/assets/icons/mailResend.svg?react';
import Marker from '@/assets/icons/marker.svg?react';
import People from '@/assets/icons/people.svg?react';
import Phone from '@/assets/icons/phone.svg?react';
import PlusRounded from '@/assets/icons/plusRounded.svg?react';
import Settings from '@/assets/icons/settings.svg?react';
import Star from '@/assets/icons/star.svg?react';
import ThumbDown from '@/assets/icons/thumbDown.svg?react';
import ThumbUp from '@/assets/icons/thumbUp.svg?react';
import Trash from '@/assets/icons/trash.svg?react';
import User from '@/assets/icons/user.svg?react';
import UserAdmin from '@/assets/icons/userAdmin.svg?react';
import Warning from '@/assets/icons/warning.svg?react';

const icons = {
  Calendar: <Calendar />,
  chevron: <Chevron />,
  building: <Building />,
  arrows: <Arrows />,
  doc: <Doc />,
  people: <People />,
  settings: <Settings />,
  userAdmin: <UserAdmin />,
  close: <Close />,
  closeSmall: <CloseSmall />,
  trash: <Trash />,
  warning: <Warning />,
  mail: <Mail />,
  mailResend: <MailResend />,
  dot: <Dot />,
  copy: <Copy />,
  download: <Download />,
  edit: <Edit />,
  phone: <Phone />,
  email: <Email />,
  marker: <Marker />,
  thumbUp: <ThumbUp />,
  thumbDown: <ThumbDown />,
  plusRounded: <PlusRounded />,
  industry: <Industry />,
  education: <Education />,
  arrowRight: <ArrowRight />,
  star: <Star />,
  arrowUpRight: <ArrowUpRight />,
  user: <User />,
};

export type IconName = keyof typeof icons;

interface IconProps {
  icon: IconName;
  onClick?: MouseEventHandler<HTMLElement>;
  className?: string;
  disabled?: boolean;
}

export function Icon({
  icon,
  onClick,
  className = '',
  disabled = false,
}: IconProps) {
  const getIcon = (wrappedInButton = false) => (
    <i
      className={`flex justify-center items-center ${wrappedInButton ? '' : className}`}
      onClick={wrappedInButton ? undefined : onClick}
    >
      {icons[icon]}
    </i>
  );
  return onClick ? (
    <button
      disabled={disabled}
      className={`flex justify-center items-center cursor-pointer ${className}`}
      onClick={(event) => {
        if (icon === 'copy') {
          toast.success('Copied to clipboard');
        }
        onClick(event);
      }}
    >
      {getIcon(true)}
    </button>
  ) : (
    getIcon()
  );
}
