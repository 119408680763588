import { Icon } from '@/components';

interface InputErrorProps {
  error: string;
}

export function InputError({ error }: InputErrorProps) {
  return (
    <p className="pt-1 text-xs text-red-600 flex gap-1">
      <Icon icon="warning" />
      {error}
    </p>
  );
}
