document.addEventListener('click', function (event) {
  const details = [...document.querySelectorAll('details')];
  if (!details.some((f) => f.contains(event.target as Node))) {
    details.forEach((f) => {
      f.removeAttribute('open');
    });
  } else {
    details.forEach((f) =>
      !f.contains(event.target as Node) ? f.removeAttribute('open') : '',
    );
  }
});
