import { Loader } from '@components/loader.tsx';
import { adminRoutes } from '@pages/admin/route.tsx';
import { authRoutes } from '@pages/auth/route.tsx';
import { useAuth, useAuthCheck } from '@store/auth';
import { useEffect, useState } from 'react';
import { Routes } from 'react-router-dom';

import { Role } from './api/model';
import { useQuery } from './hooks/useQuery';
import { welcomeRoutes } from './pages/guest/route';
import { useUser } from './store/user/user';

export function App() {
  const isLogged = useAuthCheck();
  const { isLoading, account } = useAuth();
  const [inviteAccepted, setInviteAccepted] = useState(false);

  const { currentUserInviteStatus } = useUser();
  const { refetch } = useQuery('acceptInvite', undefined, {
    enabled: false,
  });
  const { setCurrentUser, currentUserRoles } = useUser();
  useEffect(() => {
    setCurrentUser({
      roles: account?.idTokenClaims!.extension_Roles as string,
      email: account?.idTokenClaims!.emails![0],
      oid: account?.idTokenClaims!.oid,
      inviteAccepted: account?.idTokenClaims!
        .extension_InviteAccepted as boolean,
    });
  }, [isLogged]);

  useEffect(() => {
    if (
      isLogged &&
      !inviteAccepted &&
      currentUserRoles?.some((role) => role === Role.Candidate.valueOf()) &&
      currentUserInviteStatus === false
    ) {
      setInviteAccepted(true);
      void refetch();
    }
  }, [currentUserRoles, inviteAccepted]);

  return (
    <>
      {isLoading && <Loader isAbsolute />}
      <main>
        {!isLogged && <Routes>{authRoutes()}</Routes>}
        {isLogged && currentUserRoles?.length && (
          <Routes>
            {currentUserRoles.some(
              (role) =>
                role === Role.Admin.valueOf() ||
                role === Role.SysAdmin.valueOf(),
            )
              ? adminRoutes()
              : welcomeRoutes()}
          </Routes>
        )}
      </main>
    </>
  );
}
