import {
  InteractionRequiredAuthError,
  InteractionType,
} from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';

import { scopes } from '@/configs/msal.ts';

export const useMsalProtection = () => {
  const { instance } = useMsal();
  const { result, error: msalError } = useMsalAuthentication(
    InteractionType.Redirect,
    {
      scopes,
      account: instance.getActiveAccount() ?? undefined,
      redirectUri: '/redirect',
    },
  );

  const getToken = async (): Promise<string> => {
    try {
      return (
        result?.accessToken ??
        (await instance.acquireTokenSilent({ scopes })).accessToken
      );
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect({ scopes });
      }

      throw error;
    }
  };

  return { error: msalError, getToken };
};
