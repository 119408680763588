import { PropsWithChildren, Suspense } from 'react';

import { Loader } from '@/components';

export function WithSuspense({ children }: PropsWithChildren) {
  return (
    <Suspense fallback={<Loader isFullScreen className="p-4" />}>
      {children}
    </Suspense>
  );
}
