import { Dropdown } from '@components/dropdown.tsx';
import { Icon, IconName } from '@components/icon.tsx';
import type { AdminRouteKey } from '@pages/admin/route.tsx';
import { useAuth } from '@store/auth';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface SidebarProps {
  includeAdminLinks?: boolean;
}

const navLinks: Record<
  AdminRouteKey,
  {
    name: string;
    icon: IconName;
  }
> = {
  '/companies': {
    name: 'Companies',
    icon: 'building',
  },
  '/candidates': {
    name: 'Candidates',
    icon: 'people',
  },
  // '/documents': {
  //   name: 'Documents',
  //   icon: 'doc',
  // },
  // '/shifts': {
  //   name: 'Shifts',
  //   icon: 'arrows',
  // },
  // '/admins': {
  //   name: 'Admins',
  //   icon: 'userAdmin',
  // },
  '/settings': {
    name: 'Settings',
    icon: 'settings',
  },
};

export function Sidebar({ includeAdminLinks = false }: SidebarProps) {
  const { pathname } = useLocation();
  const { logoutRedirect } = useAuth();

  const links = useMemo(
    () =>
      Object.entries(navLinks).map(([path, { name, icon }]) => {
        const isActive = pathname.includes(path);

        return (
          <Link
            key={path}
            to={path}
            className={`group/link rounded border-1 flex flex-col justify-center items-center text-xs gap-1 p-1 hover:bg-gray-700 hover:border-transparent transition-colors ${
              isActive
                ? 'bg-indigo-900 border-indigo-700'
                : 'border-transparent'
            }`}
          >
            <Icon
              icon={icon}
              className={`group-hover/link:text-gray-200 transition-colors ${
                isActive ? 'text-indigo-200' : 'text-gray-400 '
              }`}
            />
            <p
              className={`group-hover/link:text-white transition-colors ${
                isActive ? 'text-white' : 'text-gray-300'
              }`}
            >
              {name}
            </p>
          </Link>
        );
      }),
    [pathname],
  );

  return (
    <>
      <div className="lg:flex-[0_0_80px]"></div>
      <aside className="px-1 py-2 lg:fixed z-10 top-0 bottom-0 lg:flex-[0_0_80px] flex flex-col bg-black gap-4">
        <div className="bg-gray-800 p-[10px] rounded flex">
          <img
            src="https://placehold.co/25x25"
            className="flex rounded-full"
            alt="profile image"
          />
          <Dropdown title={<Icon icon="chevron" className="text-gray-400" />}>
            <li>
              <a onClick={logoutRedirect}>Logout</a>
            </li>
          </Dropdown>
        </div>
        {includeAdminLinks && (
          <nav className="flex flex-col gap-3">{links}</nav>
        )}
      </aside>
    </>
  );
}
