import axiosInstance, { AxiosError } from 'axios';

import { Option } from '@/components';

import {
  AvailabilityBatchPostRequestDto,
  AvailabilityPostDto,
  BasicAuth,
  GetAllLocationsDto,
  GetAllLocationsTableGetDto,
  GetCandidateExperienceResponse,
  GetCandidateOptions,
  GetCandidateResponse,
  GetInvitationsResponse,
  GetLocationResponse,
  GetProfessionResponse,
  GetSkillResponse,
  IdBody,
  PostCandidateWorkExperienceBody,
  PostCompanyDto,
  PostLocationDto,
  PostProfessionDto,
  PostSkillDto,
  ProfessionOptionsResponse,
  PutCandidateCommentsBody,
  PutCandidateContactsBody,
  PutCandidateHeaderDto,
  PutCandidateSkillsBody,
  PutCandidateWorkExperienceBody,
  PutCompatibilityDto,
  PutLocationCommentsBody,
  PutLocationDetailsDto,
  PutLocationHeaderInfoDto,
  PutProfessionDto,
  PutSkillDto,
  ResendInvitationBody,
  SelectOption,
  SendInviteBody,
  SendInviteResponse,
} from './dtos.ts';

class BaseAPI {
  protected axios = axiosInstance.create({
    baseURL: import.meta.env.VITE_API_URL,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  setToken(token: string) {
    this.axios.defaults.headers.authorization = `Bearer ${token}`;
  }
}

class API extends BaseAPI {
  inviteUser(body: SendInviteBody) {
    return this.axios.post<SendInviteResponse>('/user/invite', body);
  }

  getInvitations() {
    return this.axios.get<GetInvitationsResponse>('/user/invitations');
  }

  resendInvite(body: ResendInvitationBody) {
    return this.axios.post<never>('/user/resendInvite', body);
  }

  deleteInvite({ id }: IdBody) {
    return this.axios.delete<never>(`/user/invitations?id=${id}`);
  }
  getProfessions() {
    return this.axios.get<ProfessionOptionsResponse[]>('/professions/options');
  }

  async getCandidate(body: IdBody) {
    const { data } = await this.axios.get<GetCandidateResponse>(
      `/candidateAdministration/${body.id}`,
    );
    return { data };
  }

  async putCandidateContacts(body: PutCandidateContactsBody) {
    const { data } = await this.axios.put<never>(
      `/candidateAdministration/${body.id}/contacts`,
      body,
    );
    return { data };
  }
  async putCandidateComments({ id, comments }: PutCandidateCommentsBody) {
    const { data } = await this.axios.put<never>(
      `/candidateAdministration/${id}/comments`,
      { comments },
    );
    return { data };
  }

  async getCandidateSkills({ id }: IdBody) {
    const { data } = await this.axios.get<Option<string>[]>(
      `/candidateAdministration/${id}/skills`,
    );
    return { data };
  }

  async putCandidateSkills({ id, skills }: PutCandidateSkillsBody) {
    const { data } = await this.axios.put<never>(
      `/candidateAdministration/${id}/skills`,
      { skills },
    );

    return { data };
  }

  async getCandidateExperience({ id }: IdBody) {
    const { data } = await this.axios.get<GetCandidateExperienceResponse>(
      `/candidateAdministration/${id}/experience`,
    );

    return { data };
  }

  async postCandidateWorkExperience({
    id,
    ...body
  }: PostCandidateWorkExperienceBody) {
    const { data } = await this.axios.post<never>(
      `/candidateAdministration/${id}/workExperience`,
      body,
    );

    return { data };
  }

  async putCandidateWorkExperience({
    candidateId,
    ...body
  }: PutCandidateWorkExperienceBody) {
    const { data } = await this.axios.put<never>(
      `/candidateAdministration/${candidateId}/workExperience`,
      body,
    );

    return { data };
  }

  getSkillsTable() {
    return this.axios.get<GetSkillResponse[]>(
      '/administration/selectAllSkillsTable',
    );
  }

  async getSkill({ id }: IdBody) {
    return await this.axios.get<GetSkillResponse>(
      `/administration/skill/${id}`,
    );
  }

  async postSkill(body: PostSkillDto) {
    return await this.axios.post<never>(`/administration/skill`, body);
  }

  async putSkill({ id, ...body }: PutSkillDto) {
    return await this.axios.put<never>(`/administration/skill/${id}`, body);
  }

  async deleteSkill({ id }: IdBody) {
    return await this.axios.delete<never>(`/administration/skill/${id}`);
  }

  async getProfessionsTable() {
    return await this.axios.get<GetProfessionResponse[]>(
      '/administration/selectAllProfessionsTable',
    );
  }

  async getProfession({ id }: IdBody) {
    return await this.axios.get<GetProfessionResponse>(
      `/administration/profession/${id}`,
    );
  }

  async postProfession(body: PostProfessionDto) {
    return await this.axios.post<never>(`/administration/profession`, body);
  }

  async putProfession({ id, ...body }: PutProfessionDto) {
    return await this.axios.put<never>(
      `/administration/profession/${id}`,
      body,
    );
  }

  async deleteProfession({ id }: IdBody) {
    return await this.axios.delete<never>(`/administration/profession/${id}`);
  }

  async indexCandidates({ username, password }: BasicAuth) {
    return await this.axios.post<never>(
      '/candidateIndex/indexCandidates',
      {},
      {
        auth: {
          username,
          password,
        },
      },
    );
  }

  async deleteIndexCandidates({ username, password }: BasicAuth) {
    return await this.axios.delete<never>('/candidateIndex/indexCandidates', {
      auth: {
        username,
        password,
      },
    });
  }

  async putCandidateHeader({ id, ...body }: PutCandidateHeaderDto) {
    return await this.axios.put<never>(
      `/candidateAdministration/${id}/candidateHeader`,
      body,
    );
  }

  async enableLogin(azureID: string) {
    return await this.axios.patch<never>(`/user/enableLogin`, { azureID });
  }

  async disableLogin(azureID: string) {
    return await this.axios.patch<never>(`/user/disableLogin`, { azureID });
  }

  async postUpdateAvailability({
    id,
    startTimestamp,
    endTimestamp,
    notes,
  }: AvailabilityPostDto) {
    return await this.axios.post<never>(`availability/updateAvailability`, {
      id,
      startTimestamp,
      endTimestamp,
      notes,
    });
  }

  async batchAvailabilities(body: AvailabilityBatchPostRequestDto) {
    return await this.axios.post<never>(
      `availability/batchAvailabilities`,
      body,
    );
  }

  async getAllLocationsTable(body: GetAllLocationsTableGetDto) {
    return this.axios.get<GetAllLocationsDto>('/companies/locationsTable', {
      params: {
        take: body.take,
        skip: body.skip,
        query: body.query,
      },
    });
  }

  async getLocationProfile(body: IdBody) {
    return await this.axios.get<GetLocationResponse>(
      `companies/location/${body.id}`,
    );
  }

  async postCompany(body: PostCompanyDto) {
    return await this.axios.post<never>('companies/company', body);
  }

  async postLocation(body: PostLocationDto) {
    return await this.axios.post<never>('companies/location', body);
  }

  async getCompanyOptions() {
    return await this.axios.get<Option<string>[]>('companies/companySelect');
  }

  async putLocationHeaderInfo({ id, ...body }: PutLocationHeaderInfoDto) {
    return await this.axios.put<never>(
      `/companies/location/${id}/updateHeaderInfo`,
      body,
    );
  }

  async deleteLocation({ id }: IdBody) {
    return await this.axios.delete<never>(`/companies/location/${id}`);
  }

  async getDocuments({ azureID }: { azureID: string }) {
    return await this.axios.post<{ azureID: string; fileNames: string[] }>(
      `/file/listFiles`,
      { azureID },
    );
  }

  async uploadDocument({ formData }: { formData: FormData }) {
    return await this.axios.post<never>(`/file/uploadFiles`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async uploadPublicDocument({ formData }: { formData: FormData }) {
    return await this.axios.post<never>(`/file/uploadPublicFiles`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async downloadDocument({
    azureID,
    fileName,
  }: {
    azureID: string;
    fileName: string;
  }) {
    return await this.axios.post<never>(
      `/file/downloadFile`,
      {
        azureID,
        fileName,
      },
      { responseType: 'blob' },
    );
  }

  async deleteDocument({
    azureID,
    fileName,
  }: {
    azureID: string;
    fileName: string;
  }) {
    return await this.axios.post<never>(`/file/deleteFile`, {
      azureID,
      fileName,
    });
  }

  async putLocationDetails({ id, ...body }: PutLocationDetailsDto) {
    return await this.axios.put<never>(
      `/companies/location/${id}/details`,
      body,
    );
  }

  async putLocationComments({ id, comments }: PutLocationCommentsBody) {
    return await this.axios.put<never>(`/companies/location/${id}/comments`, {
      comments,
    });
  }

  async getCandidatesOptions({ category }: GetCandidateOptions) {
    return await this.axios.get<SelectOption[]>(
      `/candidateAdministration/location/options?category=${category ?? ''}`,
    );
  }

  async putLocationCompatibility({ id, compatibility }: PutCompatibilityDto) {
    return await this.axios.put<never>(
      `/companies/location/${id}/compatibility`,
      {
        compatibility,
      },
    );
  }

  async getLocationOptions() {
    return await this.axios.get<SelectOption[]>(`/companies/location/options`);
  }

  async putCandidateCompatibility({ id, compatibility }: PutCompatibilityDto) {
    return await this.axios.put<never>(
      `/candidateAdministration/${id}/compatibility`,
      {
        compatibility,
      },
    );
  }

  async deleteCandidate({ id }: IdBody) {
    return await this.axios.delete<never>(
      `/candidateAdministration/${id}/delete`,
    );
  }

  async acceptInvite() {
    return await this.axios.get<never>(`/candidate/accept`);
  }
}

export type APIError = AxiosError<{
  code_error: number | string | null;
  message: string | Record<string, unknown>;
  path: string;
  statusCode: number;
  timestamp: string;
  formErrors?: Record<string, string>;
}>;
export type Endpoints = keyof Omit<API, 'setToken'>;
export type APIBody<Endpoint extends Endpoints> = Parameters<API[Endpoint]>[0];
export type APIReturnType<Endpoint extends Endpoints> = Awaited<
  ReturnType<API[Endpoint]>
>['data'];

export const api = new API();
