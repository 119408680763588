import { atom, selector, useRecoilValue, useSetRecoilState } from 'recoil';

interface User {
  email?: string;
  oid?: string;
  roles?: string;
  inviteAccepted?: boolean;
}

export const user = atom<User>({
  key: 'user',
  default: {
    email: undefined,
    oid: undefined,
    roles: undefined,
    inviteAccepted: undefined,
  },
});

export const currentUserEmail = selector({
  key: 'userEmail',
  get({ get }) {
    return get(user).email;
  },
});

export const currentUserInviteStatus = selector({
  key: 'currentUserInviteStatus',
  get({ get }) {
    return get(user).inviteAccepted;
  },
});

export const currentUser = selector({
  key: 'currentUser',
  get({ get }) {
    return get(user);
  },
});

export const currentUserRoles = selector({
  key: 'userRoles',
  get({ get }) {
    return get(user).roles?.split(',');
  },
});

export const useUser = () => {
  const setUser = useSetRecoilState(user);
  const setCurrentUser = ({ email, oid, roles, inviteAccepted }: User) => {
    setUser({
      email,
      oid,
      roles,
      inviteAccepted,
    });
  };
  const clearUser = () => {
    setUser({
      email: undefined,
      oid: undefined,
      roles: undefined,
    });
  };

  return {
    setCurrentUser,
    clearUser,
    currentUserEmail: useRecoilValue(currentUserEmail),
    currentUser: useRecoilValue(currentUser),
    currentUserRoles: useRecoilValue(currentUserRoles),
    currentUserInviteStatus: useRecoilValue(currentUserInviteStatus),
  };
};
