import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import './utils/globalListeners.ts';

import { MsalProvider } from '@azure/msal-react';
import { ToastContainer } from '@components/toastContainer.tsx';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { APIError } from '@/api';
import { initMsal } from '@/configs/msal.ts';
import { handleError } from '@/hooks/useQuery';

import { App } from './app';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      handleError(error as APIError);
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      handleError(error as APIError);
    },
  }),
});

const msalInstance = initMsal();

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <RecoilRoot>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <MsalProvider instance={msalInstance}>
            <App />
            <ToastContainer />
          </MsalProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </RecoilRoot>
  </React.StrictMode>,
);
