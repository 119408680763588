import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { AuthRoute } from '@pages/auth/route.tsx';

export const useAuth = () => {
  const { instance, inProgress } = useMsal();

  const logoutRedirect = async () => {
    await instance.logoutRedirect({
      postLogoutRedirectUri: AuthRoute.Login,
    });
  };

  return {
    account: instance.getActiveAccount(),
    get isLogged() {
      return !!this.account;
    },
    isLoading: inProgress !== InteractionStatus.None,
    logoutRedirect,
  };
};
