import { PropsWithChildren, ReactNode } from 'react';

interface DropdownProps {
  title: ReactNode;
}

export function Dropdown({
  title,
  children,
}: PropsWithChildren<DropdownProps>) {
  return (
    <details className="dropdown flex flex-1 cursor-pointer">
      <summary className="flex flex-1 items-center justify-center h-full">
        {title}
      </summary>
      <ul className="p-2 shadow-2xl menu dropdown-content z-[1] bg-base-100 rounded-md w-52 border border-b-gray-300">
        {children}
      </ul>
    </details>
  );
}
