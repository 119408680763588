import { InputError } from '@components/input/error.tsx';
import { clsx } from 'clsx';
import { forwardRef, JSX, useId } from 'react';

type CheckboxProps = Omit<JSX.IntrinsicElements['input'], 'type'> & {
  label?: string;
  error?: string;
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ id, label, error, className, ...props }, ref) => {
    const generatedId = useId();
    const checkboxId = id ?? generatedId;

    return (
      <div
        className={clsx(
          'text-sm text-gray-900 flex flex-col gap-0.5',
          className,
        )}
      >
        <label htmlFor={checkboxId} className="flex gap-2">
          <input
            ref={ref}
            id={checkboxId}
            type="checkbox"
            className="accent-indego-500"
            {...props}
          />
          {label && <p>{label}</p>}
        </label>
        {error && <InputError error={error} />}
      </div>
    );
  },
);

Checkbox.displayName = 'Checkbox';
