import { clsx } from 'clsx';
import { forwardRef, JSX, useId } from 'react';

import { InputError } from './error.tsx';
import { InputLabel } from './label.tsx';

type InputProps = JSX.IntrinsicElements['input'] & {
  label?: string;
  error?: string;
  textArea?: boolean;
  inputClassName?: string;
};

export const Input = forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  InputProps
>(
  (
    {
      id,
      error,
      label,
      className,
      type = 'text',
      autoComplete = 'off',
      textArea = false,
      inputClassName,
      ...props
    },
    ref,
  ) => {
    const generatedId = useId();
    const inputId = id ?? props.name ?? generatedId;
    const inputProps = {
      ref,
      autoComplete,
      id: inputId,
      className: clsx(
        inputClassName,
        'placeholder-gray-500 rounded border-1 outline-indigo-500 p-2 w-full flex-1',
        error ? 'border-red-600' : 'border-gray-300',
      ),
      ...props,
    };

    return (
      <div
        className={clsx(
          'text-sm text-gray-900 flex flex-col gap-0.5',
          className,
        )}
      >
        {label && (
          <InputLabel
            label={label}
            id={inputId}
            aria-label={props['aria-label']}
          />
        )}
        {textArea ? (
          // @ts-expect-error pass common props
          <textarea {...inputProps} />
        ) : (
          // @ts-expect-error pass r
          <input type={type} {...inputProps} />
        )}

        {error && <InputError error={error} />}
      </div>
    );
  },
);

Input.displayName = 'Input';
