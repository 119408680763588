import { Icon } from '@components/icon.tsx';
import { ToastContainer as ToastifyToastContainer, Zoom } from 'react-toastify';

export function ToastContainer() {
  return (
    <ToastifyToastContainer
      toastClassName="!p-0"
      bodyClassName="relative !m-0 !p-4 text-sm font-ibm"
      stacked
      position="bottom-center"
      transition={Zoom}
      theme="dark"
      autoClose={3000}
      hideProgressBar
      closeButton={({ closeToast }) => (
        <Icon icon="closeSmall" className="p-4" onClick={closeToast} />
      )}
    />
  );
}
