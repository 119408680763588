import { clsx } from 'clsx';

interface LoaderProps {
  isFullScreen?: boolean;
  isAbsolute?: boolean;
  className?: string;
}

export function Loader({
  className,
  isFullScreen = false,
  isAbsolute = false,
}: LoaderProps) {
  return (
    <div
      className={clsx(
        'flex justify-center items-center cursor-wait',
        className,
        isAbsolute &&
          'absolute inset-0 bg-slate-50/70 cursor-not-allowed select-none',
        isFullScreen && 'flex-1',
      )}
    >
      <span className="loading loading-spinner loading-md text-indigo-500"></span>
    </div>
  );
}
