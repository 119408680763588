import {
  AccountInfo,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';

enum PolicyName {
  signUpSignIn = 'B2C_1_SignUpAndSignIn',
  signIn = 'B2C_1_SignIn',
  forgotPassword = 'B2C_1_PasswordReset',
  editProfile = 'B2C_1_ProfileEditing',
}

type Policy = [keyof typeof PolicyName, PolicyName];

const {
  VITE_AZURE_AD_TENANT_NAME,
  VITE_AZURE_AD_CLIENT_ID,
  VITE_AZURE_AD_AUTHORITY_DOMAIN,
} = import.meta.env;

const createAuthorities = (policies: Policy[]) => {
  const authorityUrl = `https://${VITE_AZURE_AD_AUTHORITY_DOMAIN}/${VITE_AZURE_AD_TENANT_NAME}`;

  return policies.reduce(
    (policies, [key, value]) => ({
      ...policies,
      [key]: {
        authority: `${authorityUrl}/${value}`,
      },
    }),
    {},
  ) as Record<keyof typeof PolicyName, { authority: string }>;
};

const policies = Object.entries(PolicyName) as Policy[];

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: policies.reduce(
    (policiesObj, [key, value]) => ({
      ...policiesObj,
      [key]: value,
    }),
    {},
  ) as Record<keyof typeof PolicyName, PolicyName>,
  authorities: createAuthorities(policies),
  authorityDomain: VITE_AZURE_AD_AUTHORITY_DOMAIN,
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
const msalConfig = {
  auth: {
    clientId: VITE_AZURE_AD_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: () => {
        // eslint-disable-next-line no-console
        // console.log('Msal logger: ', args)
      },
    },
  },
};

export const initMsal = () => {
  const msalInstance = new PublicClientApplication(msalConfig);
  // Default to using the first account if no account is active on page load
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  msalInstance.addEventCallback((event) => {
    if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      (event.payload as { account: unknown }).account
    ) {
      msalInstance.setActiveAccount(
        (event.payload as { account: AccountInfo | null }).account,
      );
    }
  });

  return msalInstance;
};

export const scopes = [
  'https://intragradclient.onmicrosoft.com/45bf0659-8768-4927-887e-8e02718d0103/admin',
];
