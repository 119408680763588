import { api, APIBody, APIError, APIReturnType, Endpoints } from '@api/api.ts';
import { QueryOptions } from '@tanstack/react-query';

import { QueryKey, useFacadeQuery } from './useFacadeQuery.ts';
import { useMsalProtection } from './useMsalProtection.tsx';

export const useQuery = <Endpoint extends Endpoints>(
  endpoint: Endpoint,
  body?: APIBody<Endpoint>,
  options?: QueryOptions<
    APIReturnType<Endpoint>,
    APIError,
    unknown,
    QueryKey<Endpoint, APIBody<Endpoint>>
  > & { enabled: boolean },
) => {
  const { getToken } = useMsalProtection();
  const { query } = useFacadeQuery();

  return query<Endpoint, APIBody<Endpoint>, APIReturnType<Endpoint>>(
    [endpoint, body],
    async ({ queryKey }) => {
      const [endpoint, body] = queryKey;
      const accessToken = await getToken();
      api.setToken(accessToken);
      // @ts-expect-error body must be passed always
      const { data } = await api[endpoint](body);
      return data;
    },
    options,
  );
};
