import { Navigate, Route } from 'react-router-dom';

import { WelcomeContainer } from './welcome';

export enum AuthRoute {
  welcome = '/welcome',
}

export const welcomeRoutes = () => (
  <>
    <Route path="*" element={<Navigate to={AuthRoute.welcome} />} />
    <Route path={AuthRoute.welcome} element={<WelcomeContainer />} />
  </>
);
