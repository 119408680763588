interface InputLabelProps {
  label: string;
  id: string;
  'aria-label'?: string;
}

export function InputLabel({ label, id, ...props }: InputLabelProps) {
  return (
    <label
      aria-label={props['aria-label']}
      htmlFor={id}
      className="font-medium mb-0.5"
    >
      {label}
    </label>
  );
}
