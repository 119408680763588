import { InteractionType } from '@azure/msal-browser';
import { useMsalAuthentication } from '@azure/msal-react';

import { scopes } from '@/configs/msal.ts';

export function Login() {
  useMsalAuthentication(InteractionType.Redirect, {
    scopes,
    redirectUri: '/redirect',
  });

  return (
    <section className="flex justify-center items-center flex-1"></section>
  );
}
