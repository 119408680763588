import { Sidebar } from '@components/sidebar.tsx';
import { Outlet } from 'react-router-dom';

export function AdminContainer() {
  return (
    <div className="lg:flex lg:m-auto lg:h-full w-full">
      <Sidebar includeAdminLinks />
      <div className="flex flex-1">
        <Outlet />
      </div>
    </div>
  );
}
