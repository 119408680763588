import { Sidebar } from '@/components';
import { useUser } from '@/store/user/user';

export function WelcomeContainer() {
  const { currentUserEmail } = useUser();

  return (
    <>
      <Sidebar />
      <div className="bg-gray-100 flex justify-center items-center h-screen w-screen ml-2">
        <div className="text-center absolute top-36">
          <img
            src="https://intragradstorageaccount.blob.core.windows.net/assets/intra-logo-purple.svg"
            alt="Logo"
            className="mx-auto mb-4"
          />
          <p className="font-sans mb-4">
            Dear <span className="font-medium">{currentUserEmail}</span>
          </p>
          <p className="text-2xl font-bold mb-4 font-serif">Welcome to Intra</p>
          <hr />
          <div className="mt-4">
            <p className="mb-2">
              Please continue by downloading the Intra app from App Store
            </p>
            <p>
              If you have any questions, or need some help, please reach out to{' '}
              <a
                href="mailto:support@intra.com"
                className="text-blue-500 hover:underline"
              >
                support@intra.com
              </a>{' '}
              {'.'}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
