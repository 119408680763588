import type { EventMessage, IdTokenClaims } from '@azure/msal-browser';
import { EventType } from '@azure/msal-browser';
import { useMsal as useMsalHook } from '@azure/msal-react';
import { compareIssuingPolicy } from '@store/auth/claimUtils.ts';
import { useEffect } from 'react';

import { b2cPolicies, scopes } from '@/configs/msal.ts';

export const useAuthCheck = () => {
  const { instance } = useMsalHook();

  const eventHandler = async (event: EventMessage) => {
    if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
      (event.payload as { account: unknown }).account
    ) {
      /**
       * For the purpose of setting an active account for UI update, we want to consider only the auth
       * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
       * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
       * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
       */
      if (
        compareIssuingPolicy(
          (event.payload as { idTokenClaims: IdTokenClaims }).idTokenClaims,
          b2cPolicies.names.editProfile,
        )
      ) {
        // retrieve the account from initial sing-in to the app
        const originalSignInAccount = instance
          .getAllAccounts()
          .find(
            (account) =>
              account.idTokenClaims?.oid ===
                (event.payload as { idTokenClaims: IdTokenClaims })
                  .idTokenClaims.oid &&
              account.idTokenClaims?.sub ===
                (event.payload as { idTokenClaims: IdTokenClaims })
                  .idTokenClaims.sub &&
              compareIssuingPolicy(
                account.idTokenClaims as IdTokenClaims,
                b2cPolicies.names.signIn,
              ),
          );

        const signInFlowRequest = {
          authority: b2cPolicies.authorities.signIn.authority,
          account: originalSignInAccount,
        };

        // silently login again with the signIn policy
        await instance.ssoSilent(signInFlowRequest);
      }

      /**
       * Below we are checking if the user is returning from the reset password flow.
       * If so, we will ask the user to reauthenticate with their new password.
       * If you do not want this behavior and prefer your users to stay signed in instead,
       * you can replace the code below with the same pattern used for handling the return from
       * profile edit flow
       */
      if (
        compareIssuingPolicy(
          (event.payload as { idTokenClaims: IdTokenClaims }).idTokenClaims,
          b2cPolicies.names.forgotPassword,
        )
      ) {
        const signInFlowRequest = {
          authority: b2cPolicies.authorities.signIn.authority,
          scopes,
        };
        await instance.loginRedirect(signInFlowRequest);
      }
    }

    if (event.eventType === EventType.LOGIN_FAILURE) {
      const errorMessage = (event.error as { errorMessage?: string })
        .errorMessage;
      // Check for forgot password error
      // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      if (errorMessage?.includes('AADB2C90118')) {
        const forgotPasswordRequest = {
          authority: b2cPolicies.authorities.forgotPassword.authority,
          scopes: [],
        };

        await instance.loginRedirect(forgotPasswordRequest);
      } else {
        await instance.logout();
      }
    }
  };

  useEffect(() => {
    const callbackId = instance.addEventCallback(eventHandler);

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  return !!instance.getActiveAccount();
};
