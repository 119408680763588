import { AdminContainer } from '@pages/admin/container.tsx';
import { Navigate, Route } from 'react-router-dom';

import { lazyImport } from '@/utils/lazyImport.ts';
import { WithSuspense } from '@/utils/withSuspence.tsx';

const { CandidateRoutes } = lazyImport(
  async () => import('@pages/admin/candidates/route.tsx'),
  'CandidateRoutes',
);

const { AdministrationRoutes } = lazyImport(
  async () => import('@pages/admin/administration/route.tsx'),
  'AdministrationRoutes',
);

const { CompanyLocationsRoutes } = lazyImport(
  async () => import('@/pages/admin/companyLocations/route'),
  'CompanyLocationsRoutes',
);

enum AdminRoute {
  Companies = '/companies',
  Candidates = '/candidates',
  // Documents = '/documents',
  // Shifts = '/shifts',
  // Admins = '/admins',
  Settings = '/settings',
}

export type AdminRouteKey = AdminRoute;

export const adminRoutes = () => (
  <>
    <Route element={<AdminContainer />}>
      <Route path="*" element={<Navigate to={AdminRoute.Candidates} />} />
      <Route
        path={`${AdminRoute.Companies}/*`}
        element={
          <WithSuspense>
            <CompanyLocationsRoutes />
          </WithSuspense>
        }
      />
      <Route
        path={`${AdminRoute.Candidates}/*`}
        element={
          <WithSuspense>
            <CandidateRoutes />
          </WithSuspense>
        }
      />
      <Route
        path={`${AdminRoute.Settings}/*`}
        element={
          <WithSuspense>
            <AdministrationRoutes />
          </WithSuspense>
        }
      />
    </Route>
  </>
);
